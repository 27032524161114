import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, HStack, Input, SimpleGrid, VStack } from '@chakra-ui/react';

function SelectBasicFeatures(props) {  

  const features = [
        {label:'Cocheras', name:'garage'},
        {label:'Dormitorios', name:'bedrooms'},
        {label:'Baños', name:'bathrooms'},
        {label:'Unidades', name:'units'},
  ]
  const [showQuantityButton, setShowQuantityButton] = useState([]);
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [state, setState] = useState({
    units:null,
    bedrooms:null,
    bathrooms:null,
    garage:null,
  })

  useEffect(()=>{
    if(props.value && props.value !== state){
      setState(props.value);
      setShowQuantityButton(Object.keys(props.value))
    }
  },[props.value])

  
 
  const toggleButton = (button) => {
  
    if (selectedButtons.includes(button)) {
      setSelectedButtons(selectedButtons.filter((item) => item !== button));
    } else {
      setSelectedButtons([...selectedButtons, button]);
    }

    if (showQuantityButton.includes(button)) {
      setShowQuantityButton(showQuantityButton.filter((item) => item !== button));
    } else {
      setShowQuantityButton([...showQuantityButton, button]);
    }


  };


  
  const handleChange = (e) => {
    const { name, value } = e.target;
    if(state[name] == value){
      setState(prevState => {
 
        return { ...prevState, [name]: null };
      });

    }else{
      setState(prevState => {
        return { ...prevState, [name]: value };
      });
    }
   
    e.target.type = 'value'
    props.onChange(e);
  };

  return (
    <SimpleGrid p={5} w={'100%'} spacing={1} borderBottomRadius='lg' bg='rgba(241, 243, 244)' columns={{base:'1',sm:'1',md:'4'}} row={3}>
                    {features.map((elem, index)=>{
                      return( 
                      <VStack  key={index } spacing={0}>
                      <Button
                       key={index + elem.name.length}
                        w='full'
                       
                        mb={0}
                        className={state[elem.name]?'selected':  'features'}
                        borderBottomRadius={showQuantityButton.includes(elem.name)? '0px':'md'}
                        color={state[elem.name]?"white":'black'}
                        onClick={() => toggleButton(elem.name)
                      }
                      >
                        {elem.label}
                      </Button>
                       {showQuantityButton.includes(elem.name)  && (
                       
                         <SimpleGrid p={1} spacing={2} borderBottomRadius={3}
                          w='full'         
                          bg={ 'white'}
                          color={'black'}
                          mt={0}     
                          columns={5} row={1}>
                            
                          <Button  
                           size='xs'                                                  
                          _hover={{
                            borderColor:'#E77800',
                          }}
                          className={state[elem.name] == '1'?'selected':  'not-selected'}
                          color={state[elem.name] == '1'? 'white':'black' }
                          onClick={(e)=>{
                            e.target.name = elem.name;
                            e.target.value = "1";
                            handleChange(e);

                            }} >1</Button>

                          <Button 
                           size='xs'
                           className={state[elem.name] == '2'?'selected':  'not-selected'}
                           color={state[elem.name] == '2'? 'white':'black' }
                           value='2'
                           name={elem.name}
                           onClick={(e)=>{
                            e.target.name = elem.name;
                            e.target.value = 2;
                            handleChange(e);

                            }}  >2</Button>

                          <Button  
                           size='xs'
                           className={state[elem.name] == '3'?'selected':  'not-selected'}
                           color={state[elem.name] == '3'? 'white':'black' }
                           name={elem.name}
                           value="3"
                           onClick={(e)=>{
                            e.target.name = elem.name;
                            e.target.value = "3";
                            handleChange(e);

                            }}  >3</Button>

                          <Button 
                           size='xs'
                           value="4"
                           name={elem.name}
                           className={state[elem.name] == '4'?'selected':  'not-selected'}
                           color={state[elem.name] == '4'? 'white':'black' }
                           onClick={(e)=>{
                            e.target.name = elem.name;
                            e.target.value = "4";
                            handleChange(e);

                            }}  >4</Button>
                           
                          <Button 
                          mr={3}
                          size='xs'
                          value="5+"
                          className={state[elem.name] == '5+'?'selected':  'not-selected'}
                          color={state[elem.name] == '5+'? 'white':'black' }
                          name={elem.name}
                          onClick={(e)=>{
                            e.target.name = elem.name;
                            e.target.value = "5+";
                            handleChange(e);

                            }}  >5+</Button>
                         
                          </SimpleGrid>
                       
                      )}
                      </VStack>
                      )
                    })}
                
    </SimpleGrid>
  );
}

export default SelectBasicFeatures;