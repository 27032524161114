import React, { useState,useEffect } from 'react';
import AsyncSelect from "react-select/async";
import { components, MultiValue, OptionProps } from "react-select";

const SelectCurrency = (props) => {
  const options = [
    { label: 'USD', value: 'USD' },
    { label: 'ARS', value: 'ARS' },
  ];
  //const [value, setValue] = useState( { label: 'USD', value: 'USD' })


  const [currentValue, setValue] = useState('')

  useEffect(() => {
    let { value } = props;
    let userValue;
    if (value && !currentValue) {
      userValue = options.filter(option => value.includes(option.value));
      if (userValue && value) setValue(userValue);
    }
    if(!value){
      setValue(null)
    }
  }, [props.value]);





  const onChange = (selected) => {
    setValue(selected);
    
    let arrayOfSelected ={ target:{
      name:'currency' ,
      value: selected.value,
      type: 'value'
    }     
    }

    props.onChange(arrayOfSelected);
  };

  return (
    <AsyncSelect
      onChange={onChange}
      value={currentValue}
      closeMenuOnSelect={false}
      defaultOptions={options}
      hideSelectedOptions={false}
      isSearchable={false}
      placeholder={'Moneda'}
    />
  );
};
export default SelectCurrency;


