import React, { useEffect, useState } from 'react';
import { Box, Checkbox, HStack, Input } from '@chakra-ui/react';

const initialChecbox = {
  totalArea: true,
  coveredArea: false
}

function AreaCheckbox(props) {
  const [checkboxes, setCheckboxes] = useState(initialChecbox);

  const [inputValues, setInputValue] = useState({
    min:'',
    max:'',  
  });

  useEffect(() => {
    let { value } = props;
    if (!!(value?.min || value?.max)) {
     
        setInputValue(value);

    }else{
      setInputValue({
        min:'',
        max:'',  
      })
    }
  }, [props.value]);


  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    let newCheckbox = {
      totalArea: false,
      coveredArea: false
    }
    newCheckbox[name] = checked
    setCheckboxes(newCheckbox);
    if(name == 'coveredArea'){
      if(inputValues.min) {
        props.onChange({ target:{
          name:'coveredAreaMin' ,
          value: inputValues.min,
          type: 'value'
        }})
      }
      if(inputValues.max){
        props.onChange({ target:{
          name:'coveredAreaMax' ,
          value: inputValues.max,
          type: 'value'
        }})
      }
    }
    if(name == 'totalArea'){
        if(inputValues.min) {
          props.onChange({ target:{
            name:'totalAreaMin' ,
            value: inputValues.min,
            type: 'value'
          }})
        }
        if(inputValues.max){
          props.onChange({ target:{
            name:'totalAreaMax' ,
            value: inputValues.max,
            type: 'value'
          }})
        }
    }


  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputValue(prevState => {
      return { ...prevState, [name]: value };
    });
    if(checkboxes.coveredArea){
      if(name == 'min') {
        e.target.name = 'coveredAreaMin'
      }else{
        e.target.name = 'coveredAreaMax'

      }
    }
    if(checkboxes.totalArea){
        if(name == 'min') {
          e.target.name = 'totalAreaMin'
        }else{
          e.target.name = 'totalAreaMax'
        }
    }
   

    props.onChange(e);

  };

  return (
    <Box p={8}>
      <Checkbox className='text-black'
       ml={2}
       mb={2}
        name="coveredArea"
        isChecked={checkboxes?.coveredArea}
        onChange={handleCheckboxChange}
      >
        Cubierta
      </Checkbox>
      <Checkbox
      ml={2}
      mb={2}
className='text-black'
        name="totalArea"
        isChecked={checkboxes?.totalArea}
        onChange={handleCheckboxChange}
      >
        Total
      </Checkbox>
      <HStack>
      <Input
       _hover={{
        borderColor:'#E77800'
      }}
             borderColor='#E77800'
             borderRadius={3}

        _focus={{
          borderColor:'#E77800',
          boxShadow: 'none'
        }}  paddingInlineStart={3} paddingInlineEnd={0} bg={'white'} color="black" 
          _placeholder={
            {color:"black",
            fontSize:15,
          }
          }
          borderWidth={"1.5px"}
        name={"min"}
        value={inputValues.min}
        onChange={handleInputChange}
        placeholder="Desde"
      />
       <Input
          borderRadius={3}
          borderWidth={"1.5px"}

          borderColor='#E77800'
            _hover={{
              borderColor:'#E77800'
            }}
              _focus={{
            borderColor:'#E77800',
            boxShadow: 'none'
          }}   paddingInlineStart={3} paddingInlineEnd={0} bg={'white'} color="black"
          _placeholder={
            {color:"black",
            fontSize:15,
          }
          }
        name={"max"}
        value={inputValues.max}
        onChange={handleInputChange}
        placeholder="Hasta"
      />
      </HStack>
     
    </Box>
  );
}

export default AreaCheckbox;